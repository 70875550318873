<template>
<div id="list">
  <div class="list"  v-show="tab_type === 0">
    <div   class="sonlist" v-for="(item,k) in dataList[0]" v-bind:key="k">

        <a :href=item.link target="_blank">
        <img v-lazy=item.image>
        <div class="title"><span>{{item.name}}</span></div>
        </a>
    </div>
    <div class="ad"><a href="#" @click="itemhandleClick" target="_blank"><div class="ad1">申请</div><div class="ad2">广告位</div></a></div>
    
  </div>
  <div class="danbao_list"  v-show="tab_type === 1">
    <div  class="danbao" v-for="(item,k) in dataList[1]"  v-bind:key="k">
      
      <img v-lazy=item.image>
      <div class="ad_title"><span>{{item.name}}</span>
      <a :href=item.link target="_blank">
      <div class="fanwen">点击访问<span class="right_icon">></span></div>
      </a>
      </div>
    </div>
   <div class="ad"><a href="#" target="_blank" @click="itemhandleClick"><div class="ad1">申请</div><div class="ad2">广告位</div></a></div>
    
  </div>
  </div>
</template>

<script>
  export default {
    props: {
        dataList: Array,
        ad_contact:String
    },
    data() {
        return {
            
        }
    },
    components: {
     
    },
    computed: {
      tab_type() {
        return this.dataList[2];
      }
    },
    methods: {
      itemhandleClick(event) {
        event.preventDefault();
        const url = this.ad_contact;
        window.open(url, '_blank');
      }
    },
    mounted() {
        //console.log('aaa');
        //console.log(this.dataList);

    },


  };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


</style>
