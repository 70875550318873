import { createApp } from 'vue';
import App from './App.vue';
import router from './router'; // 假设您的路由配置文件是 router.js
import VueLazyload from 'vue-lazyload';

import { Browser } from '@capacitor/browser';

const openLink = async (url) => {
  await Browser.open({ url });
};

document.addEventListener('DOMContentLoaded', () => {
  const links = document.querySelectorAll('a');
  links.forEach(link => {
    link.addEventListener('click', async (event) => {
      event.preventDefault();
      const url = event.target.href;
      await openLink(url);
    });
  });
});


// 导入 Vue Lazyload 插件不需要使用 Vue.use
// 只需直接导入并传递配置参数
const app = createApp(App);
app.use(router); // 使用路由


// 站点前缀,apk需要增加前缀
const sitePrefix = 'https://49xunbaogo.com';

// 使用 Vue Lazyload 插件并进行配置
app.use(VueLazyload, {
  preLoad: 1.3,
  error: require('./assets/49.png'), // 图片加载失败时显示的图片
  loading: require('./assets/lazy.gif'), // 图片加载过程中显示的图片
  attempt: 1,
  lazyComponent: true,
  filter: {
    // 自定义 filter 用于添加站点前缀
    progressive(listener) {
      listener.src = sitePrefix + listener.src;
    }
  }
});

app.mount('#app'); // 挂载到 #app 元素